import React from 'react'
import Layout from '../components/en/Layout/Layout'
import CareersPage from '../components/en/Careers/CareersPage'

const Careers = () => (
  <Layout
    title='Join Our Team | the.good.code; - Exciting Projects for Talented IT Professionals'
    description='Are you a talented and passionate IT professional? Join the.good.code; team and work on exciting projects for global clients. Browse our open positions and apply today.'
    content='We are always on the lookout for talented and passionate people to join our team. Contact us:'
    mail='hr@thegoodcode.io'
  >
    <CareersPage />
  </Layout>
)

export default Careers
