import React from 'react'
import CareerStep from '../../common/Careers/CareerStep'
import { careerSteps } from '../../../consts'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation.jsx'

const CareersJourney = () => (
  <section className='grid md:grid-cols-3 article-margin md:gap-20'>
    <FadeInUpAnimation>
      <h2 className='after:content-[";"] after:text-brand-primary font-normal'>
        candidate journey
      </h2>
    </FadeInUpAnimation>
    <div className='col-span-2'>
      <FadeInUpAnimation delay={0.3}>
        <p className='my-10 md:my-0 text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'>
          No matter of an outcome, we would like all candidates to have a
          positive experience going through the recruitment process. Here are
          the the.good.code; candidate’s journey steps to follow while we're
          getting to know them professionally and personally.
        </p>
      </FadeInUpAnimation>
      <div className='md:mt-32 mt-20'>
        {careerSteps.map((step) => (
          <CareerStep
            id={step.id}
            title={step.title}
            description={step.description}
            key={step.id}
          />
        ))}
      </div>
    </div>
  </section>
)

export default CareersJourney
