import React from 'react'
import ContentContainer from '../../common/Layout/ContentContainer'
import Quote from '../../common/Icons/Quote'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation.jsx'

const InspiringMsg = () => (
  <section className='bg-black text-white lg:py-40 py-20 lg:px-20'>
    <FadeInUpAnimation>
      <ContentContainer>
        <div className='lg:flex gap-10'>
          <div className='w-1/4 lg:my-0 my-8'>
            <Quote />
          </div>
          <div>
            <h5 className='md:text-5xl text-2xl font-light md:leading-snug leading-8'>
              “You can dream, create, design and build the most wonderful place
              in the world… but it requires people to make the dream a reality.”
            </h5>
            <div className='my-10 text-2xl'>
              <h5>Walt Disney</h5>
              <p className='text-brand-primary'>Entertainment visionary</p>
            </div>
          </div>
        </div>
      </ContentContainer>
    </FadeInUpAnimation>
  </section>
)

export default InspiringMsg
